import { ToastContainer } from 'react-toastify';

import { Portal } from 'components/portal';

export function ToastProvider() {
  return (
    <Portal>
      <ToastContainer />
    </Portal>
  );
}
