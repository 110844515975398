import { fromJS } from 'immutable';

import {
  CLEAR_MEDICINAL_PRODUCT_ITEMS,
  IMPORT_MEDICINAL_PRODUCT_ITEMS,
  POLL_AMBULATORY_PRODUCT_PROVIDERS,
  POLL_MEDICINAL_PRODUCT_IMPORT,
  POLL_MEDICINAL_PRODUCT_PROVIDERS,
  RESET_MEDICATION_IMPORT_ERRORS,
  SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS,
  SEARCH_MEDICINAL_PRODUCT_PROVIDERS,
} from 'store/modules/entities/actions/medication-management/import';

import { deindex } from 'utils/deindex';

const ITEMS_PATH = ['medicinalImportItems', 'items'];
const SEARCH_EXECUTION_PATH = ['medicinalImportItems', 'searchExecution'];
const IMPORT_EXECUTION_PATH = ['medicinalImportItems', 'importExecution'];

export function medicationImportReducer(state: any, action: any) {
  switch (action.type) {
    case SEARCH_MEDICINAL_PRODUCT_PROVIDERS.SUCCESS:
    case SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS.SUCCESS: {
      const {
        response: { execution },
      } = action.payload;

      return state
        .setIn(SEARCH_EXECUTION_PATH, fromJS(execution || {}))
        .setIn(IMPORT_EXECUTION_PATH, fromJS({}))
        .setIn(ITEMS_PATH, fromJS({}));
    }

    case POLL_MEDICINAL_PRODUCT_PROVIDERS.SUCCESS:
    case POLL_AMBULATORY_PRODUCT_PROVIDERS.SUCCESS: {
      const {
        response: {
          entities: { medicinalImportItems },
          result: { execution },
        },
      } = action.payload;

      return state
        .mergeDeepIn(ITEMS_PATH, fromJS(medicinalImportItems || {}))
        .setIn(SEARCH_EXECUTION_PATH, fromJS(execution || {}));
    }

    case IMPORT_MEDICINAL_PRODUCT_ITEMS.SUCCESS: {
      const {
        response: { execution },
        data,
      } = action.payload;

      let updatedState = state;

      data.forEach(({ identifier }) => {
        updatedState = updatedState.mergeIn(
          [...ITEMS_PATH, identifier],
          fromJS({
            scheduled: true,
            importSuccess: undefined,
            warning: undefined,
          })
        );
      });

      return updatedState.setIn(IMPORT_EXECUTION_PATH, fromJS(execution || {}));
    }

    case POLL_MEDICINAL_PRODUCT_IMPORT.SUCCESS: {
      const {
        response: { execution },
      } = action.payload;

      let updatedState = state;
      let mergeObject;

      switch (execution.state) {
        case 'completed':
          mergeObject = { scheduled: false, importSuccess: true };
          break;
        case 'errored':
          mergeObject = { scheduled: false };
          break;
        default:
          mergeObject = {};
          break;
      }

      execution.payload.identifiers.forEach(({ identifier }) => {
        if (state.getIn([...ITEMS_PATH, identifier])) {
          updatedState = updatedState.mergeIn(
            [...ITEMS_PATH, identifier],
            fromJS({
              ...mergeObject,
              warning:
                execution.state === 'completed'
                  ? execution.warnings?.find(({ source }) => identifier === source)
                  : undefined,
            })
          );
        }
      });

      return updatedState.setIn(IMPORT_EXECUTION_PATH, fromJS(execution || {}));
    }

    case SEARCH_AMBULATORY_MEDICINAL_PRODUCT_PROVIDERS.FAILURE:
    case CLEAR_MEDICINAL_PRODUCT_ITEMS: {
      return state
        .setIn(ITEMS_PATH, fromJS({}))
        .setIn(SEARCH_EXECUTION_PATH, fromJS({}))
        .setIn(IMPORT_EXECUTION_PATH, fromJS({}));
    }

    case RESET_MEDICATION_IMPORT_ERRORS: {
      return state
        .setIn(SEARCH_EXECUTION_PATH, fromJS({ errors: [], state: undefined }))
        .setIn(IMPORT_EXECUTION_PATH, fromJS({ errors: [], state: undefined }));
    }

    default: {
      return state;
    }
  }
}

export const searchExecutionSelector = (state: any): any => {
  const {
    medicinalImportItems: { searchExecution },
  } = state.entities.toJS();

  return searchExecution;
};

export const importExecutionSelector = (state: any): any => {
  const {
    medicinalImportItems: { importExecution },
  } = state.entities.toJS();

  return importExecution;
};

export const medicinalImportItemsSelector = (state: any): MedicinalImportItemT[] | undefined => {
  const {
    medicinalImportItems: { items },
  } = state.entities.toJS();

  if (Object.keys(items).length === 0) return undefined;

  return deindex(items);
};
