import { LocalStorage, StorageKey } from 'utils/storage';

import { UserToken } from './types';

export const USER_TOKEN_STORAGE_KEY = 'user_token' as StorageKey<UserToken>;

export function retrieveToken() {
  return LocalStorage.get(USER_TOKEN_STORAGE_KEY) || {};
}

export function persistToken(token: UserToken, merge = false) {
  const data = merge ? { ...retrieveToken(), ...token } : token;
  LocalStorage.set(USER_TOKEN_STORAGE_KEY, data);
}

export function forgetToken() {
  LocalStorage.remove(USER_TOKEN_STORAGE_KEY);
}
