import { Action } from 'redux';

import { RootState } from 'store';
import { registerFormErrorAction } from 'store/modules/errors';
import { LOCATION_CHANGE } from 'store/modules/router';

// Actions
//==============================================================================

export const SET_FORM = 'SET_FORM';
export const RESET_FORM = 'RESET_FORM';
export const REGISTER_FORM_VALUE = 'REGISTER_FORM_VALUE';
export const SET_CLIENT_FORM_ERRORS = registerFormErrorAction('SET_CLIENT_FORM_ERRORS');

// Action Creators
//==============================================================================

export function setForm(config: any, formName: string) {
  return {
    type: SET_FORM,
    payload: {
      config,
      formName,
    },
  };
}

export function registerFormValue(value: any, formName?: string) {
  return {
    type: REGISTER_FORM_VALUE,
    payload: {
      value,
      formName,
    },
  };
}

export function resetForm(formName: string) {
  return {
    type: RESET_FORM,
    payload: {
      formName,
    },
  };
}

export function setClientFormErrors(formErrors: any) {
  const errors = Object.keys(formErrors).reduce(
    (acc, field) => [
      ...acc,
      ...formErrors[field].map((error) => ({
        title: error,
        detail: error,
        source: field,
      })),
    ],
    [] as any[]
  );

  return {
    type: SET_CLIENT_FORM_ERRORS,
    payload: errors,
    error: true,
  };
}

// Reducer
//==============================================================================

interface FormState {
  [key: string]: Record<string, any>;
}

interface FormAction extends Action<string> {
  payload: {
    formName?: string;
    value?: any;
    config?: any;
    action?: any;
  };
}

const defaultFormName = 'global';

export const initialState = {};

export function formsReducer(state: FormState = initialState, action: FormAction): FormState {
  switch (action.type) {
    case SET_FORM: {
      const { config, formName = defaultFormName } = action.payload;

      return {
        ...state,
        [formName]: {
          form: config,
          values: {},
        },
      };
    }

    case REGISTER_FORM_VALUE: {
      const { value, formName = defaultFormName } = action.payload;

      const values = { ...state[formName].values, ...value };
      const form = { ...state[formName], values };

      return { ...state, [formName]: form };
    }

    case RESET_FORM: {
      const { formName = defaultFormName } = action.payload;

      const { [formName]: value, ...rest } = state;

      return rest;
    }

    case LOCATION_CHANGE: {
      const { payload } = action;
      if (payload.action === 'REPLACE') return state;

      return initialState;
    }

    default: {
      return state;
    }
  }
}

// Selectors
//==============================================================================

export function formSelector(state: RootState, formName: string = defaultFormName) {
  return state.form[formName]?.form;
}

export function valuesSelector(state: RootState, formName: string = defaultFormName) {
  return state.form[formName]?.values;
}

export function getFormValue(
  state: RootState,
  formName: string = defaultFormName,
  field: string,
  defaultValue: string | null | undefined = undefined
) {
  const values = valuesSelector(state, formName);

  return values?.[field] || defaultValue;
}
