export function assert(condition: any, message?: string): asserts condition {
  if (condition) return;

  if (import.meta.env.DEV && message) {
    throw new AssertionError(message);
  }

  throw new AssertionError('Assertion failed.');
}

export function isAssertionError(error: any): error is AssertionError {
  return !!error && error instanceof AssertionError;
}

class AssertionError extends Error {
  name = 'AssertionError';
}
