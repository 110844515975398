import { fromJS, Map } from 'immutable';
import { Action } from 'redux';

import { combineReducers } from 'store/utils';

import { exportCenterReducer } from './export-center';
import { medicationImportReducer } from './medication-import';
import { medicationManagementReducer } from './medication-management';
import { invalidPrescriptionsReducer } from './medication/invalid-prescriptions-overview';
import { medicationScheduleReducer } from './medication/schedule';
import { medicinalTherapyPlansReducer } from './medication/therapy-plans';
import { combinedPatientsReducer } from './patients';

export type EntitiesState = Map<any, any>;

interface EntitiesAction extends Action<string> {
  payload?: any;
}

export const initialState: EntitiesState = fromJS({
  ambulatoryMedicationSchedule: {},
  exportCenter: { exportables: { byId: {}, byResult: [] }, billingItems: {}, patients: {} },
  intradialyticPrescribableMedicinalProductItems: { byId: {} },
  invalidPrescriptionsOverview: { byId: {}, ambulatory: [], intradialytic: [] },
  labResults: {
    results: { byId: {}, byTherapyPlanPatientId: {}, byTherapyPlanId: {} },
    observationTypes: { byId: {}, byTherapyPlanPatientId: {} },
  },
  medicationValidations: {},
  medicinalAdministrationRoutes: { byId: {}, byProductId: {} },
  medicinalConsumptionSetItems: { byId: {} },
  medicinalConsumptionSets: { byId: {}, byProductId: {} },
  medicinalImportItems: { importExecution: {}, searchExecution: {}, items: {} },
  medicinalIngredients: {},
  medicinalPackages: {},
  medicinalPrices: {},
  medicinalProductIngredients: {},
  medicinalProductItems: { byId: {}, byResult: [] },
  medicinalProductParentConsumptionSetItems: { byId: {} },
  medicinalProductProviders: {},
  medicinalTherapyPlanCalendarDateEntries: {},
  medicinalTherapyPlanCalendarDates: {},
  medicinalTherapyPlanCalendars: {},
  medicinalTherapyPlans: { byId: {}, byPatientId: {} },
  patients: {},
  prescribableUnits: { byId: {}, byProductId: {} },
});

export function entitiesReducer(state: EntitiesState = initialState, action: EntitiesAction): EntitiesState {
  const entitiesReducer = combineReducers(
    medicationScheduleReducer,
    exportCenterReducer,
    invalidPrescriptionsReducer,
    medicationImportReducer,
    medicationManagementReducer,
    medicinalTherapyPlansReducer,
    combinedPatientsReducer
  );

  return entitiesReducer(state, action);
}
