import { useQuery, useQueryClient } from '@tanstack/react-query';

import { http } from 'api/client';
import { ConfigurationValue, useGetConfigurationItemKey } from 'api/endpoints/configurations';
import { UserPermissionT } from 'api/endpoints/users/$user-id';

import { HOUR_IN_MILLIS, MINUTE_IN_MILLIS } from 'utils/date';
import { LocalStorage, StorageKey } from 'utils/storage';

export type AccessType = Exclude<UserPermissionT, 'integration'>;
export type AuthStrategy = 'db' | 'ldap' | 'krb' | 'oidc';
export type ChartDataPoints = '5' | '10';
export type Country = 'BE' | 'FR' | 'GT' | null;
export type DiscardedDisposableConsumptionMethod = 'keep' | 'discard' | 'ask';
export type HdChartType = 'average_pressure' | 'blood_pressure' | 'ktv' | 'ultrafiltration' | 'weight';
export type InsurancePlanDetails = 'required' | 'optional' | 'hidden';
export type LetterSendingMethod = 'after_validation' | 'manual' | 'never';
export type PdChartType = 'blood_pressure' | 'ultrafiltration' | 'weight';
export type PosologyType = 'structured' | 'free_text';
export type ReportType =
  | 'administered_vaccinations'
  | 'autodialysis'
  | 'billing'
  | 'disposable_consumption'
  | 'historical_sessions'
  | 'incomplete_vaccinations'
  | 'medication_administration'
  | 'medication_administration_prognosis'
  | 'missed_sessions'
  | 'overall_immunization_status'
  | 'program_book'
  | 'session_sign_off'
  | 'statistics_report'
  | 'transport_overview'
  | 'transport_prognosis';
export type PatientCreationStrategy = 'automatic_ingestion' | 'query_search' | 'manual';
export type TrackCloseSessionMethod = 'automatic' | 'manual';

export interface Configurations {
  'auth.authenticator': AuthStrategy;
  'auth.enforce_password_policy': boolean;
  'auth.lockable': boolean;
  'auth.session_timeout': boolean;
  'auth.session_timeout.duration': number;
  'charts.dashboard.hd_types': HdChartType[];
  'charts.dashboard.pd_types': PdChartType[];
  'charts.number_of_data_points': ChartDataPoints;
  'country': Country;
  'display_staging_ribbon': boolean;
  'disposables.consumption_registration': boolean;
  'first_day_of_week': Day;
  'multicenter_access_rights': boolean;
  'patient.cards': boolean;
  'patient.patient_creation_strategy': PatientCreationStrategy;
  'record': boolean;
  'record.agenda': boolean;
  'record.allow_machine_created_sessions': boolean;
  'record.ambulatory_medication': boolean;
  'record.ambulatory_medication.posology.default': PosologyType;
  'record.ambulatory_medication.prescriber': boolean;
  'record.artificial_kidney_assessment': boolean;
  'record.blood_volume': boolean;
  'record.diet': boolean;
  'record.disabled_reports': ReportType[];
  'record.encounters': boolean;
  'record.exports': boolean;
  'record.exports.billing': boolean;
  'record.exports.by_treatment_modality': boolean;
  'record.exports.medication': boolean;
  'record.hemostasis': boolean;
  'record.immunizations': boolean;
  'record.insurance.plan_details': InsurancePlanDetails;
  'record.integration.adt': boolean;
  'record.intradialytic_medication_suggestions': boolean;
  'record.invalid_medication_prescriptions': boolean;
  'record.ktv_calculations': boolean;
  'record.letter_builder': boolean;
  'record.session_cancellation_deadline': number;
  'record.sessions.ask_to_bill_discarded_medication': boolean;
  'record.sessions.discarded_disposable_registration_method': DiscardedDisposableConsumptionMethod;
  'record.sessions.discarded_medication_registration': boolean;
  'record.therapy_plans': boolean;
  'record.transportation': boolean;
  'record.transportation.documents': boolean;
  'record.transportation.prescriptions': boolean;
  'record.transportation.registrations': boolean;
  'record.transportation.registrations.default_transportation_settings_task': boolean;
  'record.transportation.registrations.intermutual_service': boolean;
  'record.transportation.registrations.register_when_cancelling_a_session': boolean;
  'record.treatments.crrt': boolean;
  'record.treatments.crrt.cvvh': boolean;
  'record.treatments.crrt.cvvhd': boolean;
  'record.treatments.crrt.cvvhdf': boolean;
  'record.treatments.crrt.scuf': boolean;
  'record.treatments.hd': boolean;
  'record.treatments.hd.afb_k': boolean;
  'record.treatments.hd.hd': boolean;
  'record.treatments.hd.hdf': boolean;
  'record.treatments.pd': boolean;
  'record.treatments.pd.apd': boolean;
  'record.treatments.pd.capd': boolean;
  'record.treatments.plasmapheresis': boolean;
  'record.wound_care': boolean;
  'session.metrics.debug': boolean;
  'telegraph.agenda': boolean;
  'telegraph.backup_report.use_global_url': boolean;
  'telegraph.backup_report.per_center': boolean;
  'telegraph.letter': LetterSendingMethod;
  'telegraph.session_summary': boolean;
  'telegraph.session_summary.frequency': string;
  'track': boolean;
  'track.close_session_method': TrackCloseSessionMethod;
  'ufv_calculations': boolean;
  'user.access_type': AccessType;
  'weekend_days': Day[];
  'workbench': boolean;
}

export interface Capabilities {
  configurations: Partial<Nullable<Configurations>>;
  rights: string[];
  mode: 'authenticated' | 'unauthenticated';
  locale: string;
}

const days: Day[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

/* TODO: Remove this once export rules needs to be released 👇 */

const EXPORT_RULES_ENABLED_STORAGE_KEY = 'export_rules_enabled' as StorageKey<boolean>;

(window as any).enableExportRules = () => {
  LocalStorage.set(EXPORT_RULES_ENABLED_STORAGE_KEY, true);
  window.location.reload();
};

(window as any).disableExportRules = () => {
  LocalStorage.remove(EXPORT_RULES_ENABLED_STORAGE_KEY);
  window.location.reload();
};

export function useExportRulesEnabled() {
  return LocalStorage.get(EXPORT_RULES_ENABLED_STORAGE_KEY) === true;
}

/* TODO: Remove this once export rules needs to be released 👆 */

// fetch capabilities endpoint
export const CAPABILITIES_QUERY_KEY = ['capabilities'];

export function useCapabilities() {
  const { data, isLoading, isFetching, refetch } = useQuery(CAPABILITIES_QUERY_KEY, () => fetchCapabilities());

  return { capabilities: data, isLoading, isFetching, refetch };
}

function useConfiguration<Key extends keyof Configurations>(key: Key, fallback: Configurations[Key]) {
  // Refetch on hook mount after 15 minutes of stale data
  const { data } = useQuery(CAPABILITIES_QUERY_KEY, () => fetchCapabilities(), {
    staleTime: 15 * MINUTE_IN_MILLIS,
  });

  return data?.configurations[key] ?? fallback;
}

export function useUpdateCapabilitiesItem() {
  const queryClient = useQueryClient();
  const getConfigurationItemKey = useGetConfigurationItemKey();

  return (id: string, value: ConfigurationValue | null) => {
    const key = getConfigurationItemKey(id);
    if (!key) return;

    queryClient.setQueryData<Capabilities>(CAPABILITIES_QUERY_KEY, (capabilities) => {
      if (!capabilities) return undefined;

      return {
        ...capabilities,
        configurations: { ...capabilities.configurations, [key]: value },
      };
    });
  };
}

async function fetchCapabilities(): Promise<Capabilities> {
  const {
    data,
    config: { headers },
  } = await http.get<{ capabilities: Omit<Capabilities, 'mode'> }>('capabilities', { noCamelizeResponse: true });

  return {
    ...data.capabilities,
    mode: headers?.['Authorization'] ? 'authenticated' : 'unauthenticated',
  };
}

// System configuration hooks

export function useAccountLockable() {
  return useConfiguration('auth.lockable', false);
}

export function useAgendaExternalRoomIdentifier() {
  return useConfiguration('telegraph.agenda', false);
}

export function useAllModulesEnabled() {
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();
  const patientCardsEnabled = usePatientCardsEnabled();
  const workbenchEnabled = useWorkbenchEnabled();

  return recordEnabled && trackEnabled && patientCardsEnabled && workbenchEnabled;
}

export function useAmbulatoryIsStructuredPosologyDefault() {
  const posology = useConfiguration('record.ambulatory_medication.posology.default', 'free_text');

  return posology === 'structured';
}

export function useAmbulatoryMedicationEnabled() {
  return useConfiguration('record.ambulatory_medication', false);
}

export function useAmbulatoryPrescriptionEnabled() {
  return useConfiguration('record.ambulatory_medication.prescriber', false);
}

export function useAmbulatoryPrescriptionsEnabled() {
  const country = useCountry();
  const ambulatoryMedicationEnabled = useAmbulatoryMedicationEnabled();
  const ambulatoryPrescriptionEnabled = useAmbulatoryPrescriptionEnabled();

  const isBE = country === 'BE';
  const isFR = country === 'FR';
  const isSupported = isBE || isFR;

  return isSupported && ambulatoryMedicationEnabled && ambulatoryPrescriptionEnabled;
}

export function useArtificialKidneyAssessment() {
  return useConfiguration('record.artificial_kidney_assessment', false);
}

export function useAskToBillDiscardedMedication() {
  return useConfiguration('record.sessions.ask_to_bill_discarded_medication', false);
}

export function useAuthenticationStrategy() {
  return useConfiguration('auth.authenticator', 'db');
}

export function useBackupReportPerCenter() {
  return useConfiguration('telegraph.backup_report.per_center', false);
}

export function useBillingByTreatmentModality() {
  return useConfiguration('record.exports.by_treatment_modality', false);
}

export function useBloodVolumeEnabled() {
  return useConfiguration('record.blood_volume', false);
}

export function useChartDataPoints() {
  return useConfiguration('charts.number_of_data_points', '5');
}

export function useCountry() {
  const country = useConfiguration('country', null);

  return country ? (country.toUpperCase() as Country) : null;
}

export function useCrrtEnabled() {
  return useConfiguration('record.treatments.crrt', false);
}

export function useCrrtCvvhEnabled() {
  return useConfiguration('record.treatments.crrt.cvvh', false);
}

export function useCrrtCvvhdEnabled() {
  return useConfiguration('record.treatments.crrt.cvvhd', false);
}

export function useCrrtCvvhdfEnabled() {
  return useConfiguration('record.treatments.crrt.cvvhdf', false);
}

export function useCrrtScufEnabled() {
  return useConfiguration('record.treatments.crrt.scuf', false);
}

export function useDietEnabled() {
  return useConfiguration('record.diet', false);
}

export function useDisabledReports() {
  const disabledReports = useConfiguration('record.disabled_reports', []);
  const immunizationsEnabled = useImmunizationsEnabled();
  const transportationRegistrationEnabled = useTransportationRegistrationsEnabled();

  const result = new Set(disabledReports);

  if (!immunizationsEnabled) {
    result.add('administered_vaccinations');
    result.add('incomplete_vaccinations');
    result.add('overall_immunization_status');
  }

  if (!transportationRegistrationEnabled) {
    result.add('transport_overview');
    result.add('transport_prognosis');
  }

  return [...result];
}

export function useDiscardedDisposableConsumptionMethod() {
  return useConfiguration('record.sessions.discarded_disposable_registration_method', 'ask');
}

export function useDiscardedMedicationRegistration() {
  return useConfiguration('record.sessions.discarded_medication_registration', false);
}

export function useDisposableConsumptionRegistration() {
  return useConfiguration('disposables.consumption_registration', false);
}

export function useEncountersEnabled() {
  return useConfiguration('record.encounters', false);
}

export function useExportsBillingEnabled() {
  return useConfiguration('record.exports.billing', true);
}

export function useExportsEnabled() {
  const exportsEnabled = useConfiguration('record.exports', true);
  const exportsBillingEnabled = useExportsBillingEnabled();
  const exportsMedicationEnabled = useExportsMedicationEnabled();

  return exportsEnabled && (exportsBillingEnabled || exportsMedicationEnabled);
}

export function useExportsMedicationEnabled() {
  return useConfiguration('record.exports.medication', true);
}

export function useGlobalBackupReportUrl() {
  return useConfiguration('telegraph.backup_report.use_global_url', false);
}

export function useHemostasisEnabled() {
  return useConfiguration('record.hemostasis', false);
}

export function useHdEnabled() {
  return useConfiguration('record.treatments.hd', false);
}

export function useHdTreatmentAfbKEnabled() {
  return useConfiguration('record.treatments.hd.afb_k', false);
}

export function useHdTreatmentHdEnabled() {
  return useConfiguration('record.treatments.hd.hd', false);
}

export function useHdTreatmentHdfEnabled() {
  return useConfiguration('record.treatments.hd.hdf', false);
}

export function useHdDashboardCharts() {
  const charts = useConfiguration('charts.dashboard.hd_types', [
    'average_pressure',
    'blood_pressure',
    'ktv',
    'ultrafiltration',
    'weight',
  ]);

  return {
    hasCharts: charts.length > 0,
    showAveragePressureChart: charts.includes('average_pressure'),
    showBloodPressureChart: charts.includes('blood_pressure'),
    showKtvChart: charts.includes('ktv'),
    showUltrafiltrationChart: charts.includes('ultrafiltration'),
    showWeightChart: charts.includes('weight'),
  };
}

export function useImmunizationsEnabled() {
  return useConfiguration('record.immunizations', false);
}

export function useInsurancePlanDetails() {
  return useConfiguration('record.insurance.plan_details', 'required');
}

export function useIntradialyticMedicationSuggestionsEnabled() {
  return useConfiguration('record.intradialytic_medication_suggestions', true);
}

export function useInvalidMedicationPrescriptionsEnabled() {
  return useConfiguration('record.invalid_medication_prescriptions', false);
}

export function useKtvCalculationsEnabled() {
  return useConfiguration('record.ktv_calculations', false);
}

export function useLetterBuilderEnabled() {
  return useConfiguration('record.letter_builder', false);
}

export function useLetterSendingMethod() {
  return useConfiguration('telegraph.letter', 'never');
}

export function useManualPatientCreationEnabled() {
  return !useConfiguration('record.integration.adt', true);
}

export function useManualSessionEHRPublishingEnabled() {
  const isSessionSummaryEnabled = useConfiguration('telegraph.session_summary', false);

  const isManual = useConfiguration('telegraph.session_summary.frequency', 'always') === 'adhoc';

  return isSessionSummaryEnabled && isManual;
}

export function useAutomaticallySessionEHRPublishingAfterSessionCompletion() {
  const isSessionSummaryEnabled = useConfiguration('telegraph.session_summary', false);

  const isAutomatic = useConfiguration('telegraph.session_summary.frequency', 'always') === 'always_before_sign_off';

  return isSessionSummaryEnabled && isAutomatic;
}

export function useUFVCalculationsEnabled() {
  return useConfiguration('ufv_calculations', true);
}

export function useMulticenterAccessRights() {
  return useConfiguration('multicenter_access_rights', false);
}

export function usePasswordEnforcingAuthentication() {
  const authStrategyOIDC = useAuthenticationStrategy() === 'oidc';
  const authStrategyLDAP = useAuthenticationStrategy() === 'ldap';

  return !(authStrategyOIDC || authStrategyLDAP);
}

export function usePlasmapheresisEnabled() {
  return useConfiguration('record.treatments.plasmapheresis', false);
}

export function usePatientAgendaEnabled() {
  return useConfiguration('record.agenda', false);
}

export function usePatientCardsEnabled() {
  return useConfiguration('patient.cards', false);
}

export function usePatientCreationStrategy() {
  return useConfiguration('patient.patient_creation_strategy', 'automatic_ingestion');
}

export function usePdEnabled() {
  return useConfiguration('record.treatments.pd', false);
}

export function usePdTreatmentApdEnabled() {
  return useConfiguration('record.treatments.pd.apd', false);
}

export function usePdTreatmentCapdEnabled() {
  return useConfiguration('record.treatments.pd.capd', false);
}

export function usePdDashboardCharts() {
  const charts = useConfiguration('charts.dashboard.pd_types', ['blood_pressure', 'ultrafiltration', 'weight']);

  return {
    hasCharts: charts.length > 0,
    showBloodPressureChart: charts.includes('blood_pressure'),
    showUltrafiltrationChart: charts.includes('ultrafiltration'),
    showWeightChart: charts.includes('weight'),
  };
}

export function useRecordEnabled() {
  return useConfiguration('record', false);
}

export function useManuallyCloseSessions() {
  const closeSessionMethod = useConfiguration('track.close_session_method', 'automatic');

  return closeSessionMethod === 'manual';
}

export function useAllowMachineCreatedSessions() {
  return useConfiguration('record.allow_machine_created_sessions', false);
}

export function useRecordSessionCancellationDeadline() {
  return useConfiguration('record.session_cancellation_deadline', 2) * HOUR_IN_MILLIS;
}

export function useRegisterTransportationWhenCancelingSession() {
  return useConfiguration('record.transportation.registrations.register_when_cancelling_a_session', false);
}

export function useRightScopes() {
  const recordEnabled = useRecordEnabled();
  const trackEnabled = useTrackEnabled();

  const scopes: RightScope[] = ['admin'];

  if (recordEnabled || trackEnabled) scopes.push('patients');
  if (recordEnabled) scopes.push('record');

  return scopes;
}

export function useSessionMetricsDebug() {
  return useConfiguration('session.metrics.debug', false);
}

export function useStagingRibbon() {
  return useConfiguration('display_staging_ribbon', false);
}

export function useStrongPasswordPolicy() {
  return useConfiguration('auth.enforce_password_policy', false);
}

export function useTherapyPlansEnabled() {
  return useConfiguration('record.therapy_plans', false);
}

export function useTrackEnabled() {
  return useConfiguration('track', false);
}

export function useUserAccessType() {
  return useConfiguration('user.access_type', 'global');
}

export function useWeekDayOffset() {
  const firstDayOfWeek = useConfiguration('first_day_of_week', 'monday');

  return days.indexOf(firstDayOfWeek) as WeekDay;
}

export function useWeekendDays() {
  const weekendDays = useConfiguration('weekend_days', ['saturday', 'sunday']);

  return weekendDays.map((day) => days.indexOf(day) as WeekDay);
}

export function useWorkbenchEnabled() {
  return useConfiguration('workbench', false);
}

export function useWoundCareEnabled() {
  return useConfiguration('record.wound_care', false);
}

export function useTransportation() {
  const documentsEnabled = useTransportationDocumentsEnabled();
  const prescriptionsEnabled = useTransportationDocumentsEnabled();
  const registrationsEnabled = useTransportationRegistrationsEnabled();
  const transportEnabled = useConfiguration('record.transportation', false);

  if (!transportEnabled) {
    return false;
  }

  if (documentsEnabled === false && prescriptionsEnabled === false && registrationsEnabled === false) {
    return false;
  }

  return true;
}

export function useTransportationDocumentsEnabled() {
  const country = useCountry();
  const documentsEnabled = useConfiguration('record.transportation.documents', false);

  return (country === 'FR' || country === 'BE') && documentsEnabled;
}

export function useTransportationPrescriptionsEnabled() {
  const country = useCountry();
  const prescriptionsEnabled = useConfiguration('record.transportation.prescriptions', false);

  return country === 'FR' && prescriptionsEnabled;
}

export function useTransportationRegistrationsEnabled() {
  return useConfiguration('record.transportation.registrations', false);
}

export function useTransportationRegisterDefaultTransportationTask() {
  return useConfiguration('record.transportation.registrations.default_transportation_settings_task', false);
}

export function useTransportationIntermutualService() {
  const country = useCountry();
  const intermutualSerivce = useConfiguration('record.transportation.registrations.intermutual_service', false);

  return country === 'BE' && intermutualSerivce;
}

export function useSessionTimeoutEnabled() {
  return useConfiguration('auth.session_timeout', false);
}

export function useSessionTimeoutDuration() {
  const minutes = useConfiguration('auth.session_timeout.duration', 15);

  return Math.max(1, minutes) * MINUTE_IN_MILLIS;
}
