import { RootState } from 'store';

export function getSearchResults(state: RootState, resource: string | string[], searchIdentifier?: string | undefined) {
  const collection = state.entities.getIn(Array.isArray(resource) ? resource : [resource]);

  if (!collection) return [];

  const lastSearch = state.search[(searchIdentifier || resource) as string]?.lastSearchTerm;

  const resultIds = lastSearch ? state.search[(searchIdentifier || resource) as string].results[lastSearch] : [];

  return resultIds
    .map((id) => collection.get(id))
    .filter((result) => result !== undefined)
    .map((item) => item.toJS());
}
