import { ReactElement } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { Button } from 'components/button';
import { Icon } from 'components/icon';

import logoType from 'assets/images/logo-type.png';

export function ErrorPage({ onClickCallBack }: { onClickCallBack: () => void }) {
  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-shaking font-t3">
          <Icon>flash_on</Icon>
          <br />
          <FormattedMessage {...t.errorHeadline} />
        </h2>

        <br />

        <Button
          meaning="affirmative"
          title={t.refreshStateTitle}
          onClick={() => window.location.assign(window.location.origin)}
        >
          {t.refreshState}
        </Button>

        <Button meaning="affirmative" importance="secondary" title={t.reportError} onClick={onClickCallBack}>
          {t.reportError}
        </Button>
      </div>
    </PageWrapper>
  );
}

export function ForbiddenPage() {
  const { formatMessage } = useIntl();

  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-beating font-t3">
          <Icon>security</Icon>
          <br />
          <FormattedMessage {...t.forbiddenHeadline} />
        </h2>

        <br />

        <NavLink to="/" title={formatMessage(t.backHomeTitle)}>
          <Button meaning="affirmative">{t.backHome}</Button>
        </NavLink>
      </div>
    </PageWrapper>
  );
}

export function NotFoundPage() {
  const { formatMessage } = useIntl();

  return (
    <PageWrapper>
      <div className="error-page__container">
        <h2 className="error-page__title -is-shaking font-t3">
          <Icon>location_searching</Icon>
          <br />
          <FormattedMessage {...t.notFoundHeadline} />
        </h2>
        <br />

        <NavLink to="/" title={formatMessage(t.backHomeTitle)}>
          <Button meaning="affirmative">{t.backHome}</Button>
        </NavLink>
      </div>
    </PageWrapper>
  );
}

function PageWrapper({ children }: { children?: ReactElement }) {
  return (
    <div className="error-page">
      {children}
      <img src={logoType} className="error-page__logo" alt="NephroFlow" />
    </div>
  );
}

const t = defineMessages({
  errorHeadline: {
    id: 'error_page_error_headline',
    defaultMessage: 'Oops, looks like an error has occurred.',
  },
  notFoundHeadline: {
    id: 'error_page_not_found_headline',
    defaultMessage: 'Oops, looks like the page you were looking for cannot be found.',
  },
  forbiddenHeadline: {
    id: 'error_page_forbidden_headline',
    defaultMessage: 'Oops, looks like you are not allowed to view this page.',
  },
  refreshState: {
    id: 'error_page_refresh_state',
    defaultMessage: 'Reload the app',
  },
  refreshStateTitle: {
    id: 'error_page_refresh_state_title',
    defaultMessage: 'Reload this page',
  },
  reportError: {
    id: 'error_page_report_title',
    defaultMessage: 'Report error',
  },
  backHome: {
    id: 'error_page_back_home',
    defaultMessage: 'Bring me back home',
  },
  backHomeTitle: {
    id: 'error_page_back_home_title',
    defaultMessage: 'Back to dashboard',
  },
});
