import { useCapabilities } from 'hooks/use-config';

export function useAppLocale(): string {
  const { capabilities } = useCapabilities();
  const locale = capabilities?.locale?.replace('_', '-');
  const country = capabilities?.configurations.country;

  if (locale && locale.indexOf('-') !== -1) return locale;
  if (locale && country) return `${locale}-${country}`;
  if (locale) return locale;

  return 'en-GB';
}
