import { useQuery, useQueryClient } from '@tanstack/react-query';

import { http } from 'api/client';

export type ConfigurationValue = null | string | number | boolean | string[];

export interface Configuration {
  id: string;
  type: 'string' | 'integer' | 'boolean' | 'list';
  value?: ConfigurationValue | null;
  default?: ConfigurationValue | null;
  key: string;
  title: string;
  description: string;
  dependencies: string[];
  dependenciesSatisfied: boolean;
  allowedValues: OptionT[];
  required: boolean;
}

export async function fetchConfigurations() {
  const { data } = await http.get<{ configurations: Configuration[] }>('configurations');

  return data.configurations;
}

export async function updateConfiguration({ id, value }: { id: string; value: ConfigurationValue }) {
  const { data } = await http.put<{ configuration: Configuration }>(`configurations/${id}`, {
    configuration: { value },
  });

  return data.configuration;
}

export const CONFIGURATIONS_QUERY_KEY = ['configurations'];

export function useConfigurations() {
  const { data: configurations, isLoading } = useQuery(CONFIGURATIONS_QUERY_KEY, () => fetchConfigurations());

  return { configurations, isLoading };
}

export function useUpdateConfigurationItem() {
  const queryClient = useQueryClient();

  return (id: string, value: ConfigurationValue | null) => {
    queryClient.setQueryData<Configuration[]>(CONFIGURATIONS_QUERY_KEY, (configurations) => {
      if (!configurations) return undefined;

      return configurations.map((config) => (config.id === id ? { ...config, value } : config));
    });
  };
}

export function useGetConfigurationItemKey() {
  const queryClient = useQueryClient();

  return (id: string) => {
    return queryClient.getQueryData<Configuration[]>(CONFIGURATIONS_QUERY_KEY)?.find((config) => config.id === id)?.key;
  };
}
