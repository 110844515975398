import { configureStore, MiddlewareArray } from '@reduxjs/toolkit';

import { createNetworkMiddleware } from 'api/store-middleware';

import { reducer } from './reducer';

export const store = configureStore({
  reducer: reducer,
  middleware: new MiddlewareArray().concat(createNetworkMiddleware<RootState>()),
  devTools: import.meta.env.DEV,
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
