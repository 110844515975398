import axios, { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import { stringify } from 'qs';

import { forgetToken, persistToken, retrieveToken } from 'auth/token-storage';
import { UserToken } from 'auth/types';

import { camelizeKeys, decamelizeKeys } from 'utils/camelize';

import { BASE_PATH } from './url';

const refreshURL = 'auth/refresh';
const http = axios.create({ baseURL: BASE_PATH });

let refreshDefer: Deferred | undefined;

function httpInterceptor(refreshSuccess: (token: UserToken) => void, refreshFailure: () => void) {
  http.interceptors.request.use(
    (config) => {
      const { _isRetry, _isAuthRefresh, noDecamelizePayload } = config;

      return {
        ...config,

        transformRequest(data, headers) {
          const { access, refresh } = retrieveToken();

          if (access && !_isAuthRefresh) {
            headers['Authorization'] = `Bearer ${access}`;
          }

          if (refresh && _isAuthRefresh) {
            headers['X-Refresh-Token'] = `Bearer ${refresh}`;
          }

          headers['Content-Type'] = data instanceof FormData ? 'multipart/form-data' : 'application/json';

          if (!(data instanceof FormData) && !_isRetry) {
            data = data ? JSON.stringify(noDecamelizePayload ? data : decamelizeKeys(data)) : undefined;
          }

          return data;
        },

        paramsSerializer: {
          serialize: (params) =>
            stringify(decamelizeKeys(params), {
              indices: false,
              arrayFormat: 'brackets',
            }),
        },
      };
    },

    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      const { noCamelizeResponse } = response.config;

      if (noCamelizeResponse) return response;

      return {
        ...response,
        data: camelizeKeys(response.data),
      };
    },

    async (error: unknown) => {
      if (!isAxiosError(error)) {
        return Promise.reject(error);
      }

      const { _isRetry, _isAuthRefresh } = error.config || {};

      if (_isAuthRefresh) {
        forgetToken();
        refreshFailure();

        refreshDefer?.resolve('error');
        refreshDefer = undefined;

        return Promise.reject(error);
      }

      if (error.response?.status === 401 && !_isRetry) {
        const retryRequest = {
          ...error.config,
          headers: {},
          _isRetry: true,
          _isAuthRefresh: undefined,
        };

        if (!refreshDefer) {
          refreshDefer = defer();

          return http
            .post<UserToken>(refreshURL, undefined, { _isAuthRefresh: true })
            .then((response) => {
              const token = camelizeKeys(response.data) as UserToken;

              persistToken(token, true);
              refreshSuccess(token);

              refreshDefer?.resolve('success');
              refreshDefer = undefined;

              return http(retryRequest);
            })
            .catch(() => Promise.reject(error));
        } else {
          return refreshDefer.promise.then((status) =>
            status === 'error' ? Promise.reject(error) : http(retryRequest)
          );
        }
      }

      return Promise.reject(error);
    }
  );
}

type DeferredStatus = 'error' | 'success';

interface Deferred {
  promise: Promise<DeferredStatus>;
  resolve: (status: DeferredStatus) => void;
}

function defer() {
  const deferred = {};

  deferred['promise'] = new Promise((resolve) => {
    deferred['resolve'] = resolve;
  });

  return deferred as Deferred;
}

function testErrorStatusCode(error: any, statusCode: number): error is AxiosError {
  return isAxiosError(error) ? error.response?.status === statusCode : false;
}

export { http, httpInterceptor, testErrorStatusCode };
