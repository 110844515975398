import { ReactElement, useEffect } from 'react';

import { Capabilities, useCapabilities } from 'hooks/use-config';

import { Loading } from 'components/loading';

export function CapabilitiesProvider({ children, mode }: { children: ReactElement; mode: Capabilities['mode'] }) {
  const { capabilities, isLoading, isFetching, refetch } = useCapabilities();

  useEffect(() => {
    if (!isFetching && capabilities?.mode && capabilities?.mode !== mode) {
      refetch();
    }
  }, [isFetching, capabilities?.mode, mode, refetch]);

  return isLoading || !capabilities || mode !== capabilities.mode ? <Loading /> : <>{children}</>;
}
