import {
  UPDATE_CARE_TEAM_SUCCESS,
  UPDATE_PATIENT_ALLERGIES_KNOWN,
  UPDATE_PATIENT_AVATAR,
  UPDATE_PATIENT_CONTACT_INFO,
  UPDATE_PATIENT_DISORDER,
  UPDATE_PATIENT_HOME_NURSING,
  UPDATE_PATIENT_MEDICAL_INFO,
  UPDATE_PATIENT_PHONE,
  UPDATE_PATIENT_REFERRER_TYPE,
  UPDATE_PATIENT_RESIDENCE_TYPE,
} from 'store/modules/entities/actions/patients';
import { UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS } from 'store/modules/entities/actions/patients/transplant-status';
import {
  mergePatientCareTeamHandler,
  mergePatientHandler,
  patientsReducer,
} from 'store/modules/entities/reducers/patients/patients';
import { updatePatientBasicInfo } from 'store/modules/entities/reducers/patients/update-patient-basic-info';
import { updatePatientTransplantStatus } from 'store/modules/entities/reducers/patients/update-patient-transplant-status';
import { combineReducers } from 'store/utils';

export const combinedPatientsReducer = combineReducers(indexReducer, patientsReducer);

function indexReducer(state: any, action: any) {
  switch (action.type) {
    case UPDATE_PATIENT_TRANSPLANT_ELIGIBILITY_STATUS.SUCCESS: {
      return updatePatientTransplantStatus(state, action);
    }

    case UPDATE_PATIENT_RESIDENCE_TYPE.SUCCESS:
    case UPDATE_PATIENT_REFERRER_TYPE.SUCCESS: {
      return updatePatientBasicInfo(state, action);
    }

    case UPDATE_PATIENT_PHONE.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_AVATAR.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_DISORDER.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_CONTACT_INFO.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_MEDICAL_INFO.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_ALLERGIES_KNOWN.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_PATIENT_HOME_NURSING.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case UPDATE_CARE_TEAM_SUCCESS: {
      return mergePatientCareTeamHandler(state, action);
    }

    default: {
      return state;
    }
  }
}
