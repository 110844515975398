import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  target?: Element | null;
  children: ReactNode;
}

export function Portal({ target = document.querySelector('#portal-root'), children }: Props) {
  return target ? createPortal(children, target) : null;
}
