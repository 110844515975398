import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { useCallback } from 'react';

import { apiUrl } from 'api/url';

import { camelizeKeys } from 'utils/camelize';

import { useAuthenticationStrategy } from 'hooks/use-config';

import { forgetToken, persistToken, retrieveToken } from './token-storage';
import { AuthCredentials, Authenticator, UserToken } from './types';

class MicrosoftAuthenticator implements Authenticator {
  msalInstance = new PublicClientApplication({
    auth: {
      authority: import.meta.env.REACT_APP_MSAL_AUTHORITY,
      clientId: import.meta.env.REACT_APP_MSAL_CLIENT_ID || '',
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  });

  async login(): Promise<UserToken> {
    const loginRequest = {
      scopes: ['email'],
    };

    const authenticationResult = await this.msalInstance.loginPopup(loginRequest);

    return authenticate({ id_token: authenticationResult.idToken });
  }

  async logout(): Promise<void> {
    await invalidateToken();
    this.msalInstance.logoutRedirect();
  }
}

class UsernamePasswordAuthenticator implements Authenticator {
  login(credentials: AuthCredentials): Promise<UserToken> {
    return authenticate(credentials);
  }

  async logout(): Promise<void> {
    await invalidateToken();
  }
}

async function authenticate(credentials: AuthCredentials): Promise<UserToken> {
  try {
    const response = await axios.post<UserToken>(apiUrl('auth'), credentials, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const data = camelizeKeys(response.data) as UserToken;

    persistToken(data);

    return data;
  } catch (error: any) {
    const { response } = error;

    forgetToken();

    if (response.status === 401) {
      throw new Error(response.data.errors?.[0]?.title ?? response.statusText);
    }

    throw new Error(response.statusText);
  }
}

async function invalidateToken() {
  const { refresh } = retrieveToken();

  try {
    await axios.delete(apiUrl('auth'), {
      headers: {
        'X-Refresh-Token': `Bearer ${refresh}`,
      },
    });
  } catch (error) {}

  forgetToken();
}

export function useAuthenticator(): () => Authenticator {
  const authStrategy = useAuthenticationStrategy();

  return useCallback(
    () => (authStrategy === 'oidc' ? new MicrosoftAuthenticator() : new UsernamePasswordAuthenticator()),
    [authStrategy]
  );
}
