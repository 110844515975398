type CustomStorageEvent<T> = Omit<StorageEvent, 'key' | 'newValue' | 'oldValue'> & {
  key: string;
  newValue: T | null;
  oldValue: T | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type StorageKey<T> = string & Brand<'storage_key'>;

class StorageHelper {
  constructor(private storage: Storage = localStorage) {}

  get<T>(key: StorageKey<T>) {
    return decodeStorageValue<T>(this.getRaw(key));
  }

  set<T>(key: StorageKey<T>, value: T) {
    this.setRaw(key, encodeStorageValue(value));
  }

  getRaw<T>(key: StorageKey<T>) {
    return this.storage.getItem(createKey(key));
  }

  setRaw<T>(key: StorageKey<T>, value: string) {
    this.storage.setItem(createKey(key), value);
  }

  remove<T>(key: StorageKey<T>) {
    this.storage.removeItem(createKey(key));
  }

  clear() {
    this.storage.clear();
  }

  listen<T>(key: StorageKey<T>, callback: (event: CustomStorageEvent<T>) => void) {
    const listener = (event: StorageEvent) => {
      if (event.storageArea === this.storage && event.key === createKey(key) && event.isTrusted) {
        const customEvent: CustomStorageEvent<T> = {
          ...event,
          key,
          newValue: decodeStorageValue<T>(event.newValue),
          oldValue: decodeStorageValue<T>(event.oldValue),
        };

        callback(customEvent);
      }
    };

    window.addEventListener('storage', listener);

    return function cleanup() {
      window.removeEventListener('storage', listener);
    };
  }
}

export const LocalStorage = new StorageHelper(localStorage);
export const SessionStorage = new StorageHelper(sessionStorage);

function createKey<T>(key: StorageKey<T>) {
  return `__nf_${key}__`;
}

function encodeStorageValue(value: any) {
  return JSON.stringify(value);
}

function decodeStorageValue<T>(json: string | null) {
  if (json === null) return null;

  let decoded: T | null = null;
  try {
    decoded = JSON.parse(json);
  } catch (error) {}

  return decoded;
}
