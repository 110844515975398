import { Action } from 'redux';

import { CANCEL_ACTION_BAR_ACTIVITY, START_ACTIVITY } from 'store/modules/activity';
import { FORBIDDEN, METHOD_NOT_ALLOWED, NOT_FOUND, PAYLOAD_TOO_LARGE } from 'store/modules/network';
import { LOCATION_CHANGE } from 'store/modules/router';

import { containsUuid } from 'utils/contains-uuid';
import { getType } from 'utils/get-type';

// Actions
//==============================================================================

export const RESET_ERROR = 'RESET_ERROR';

// Action Creators
//==============================================================================

export function resetError() {
  return { type: RESET_ERROR };
}

// Reducer
//==============================================================================

type ErrorsState = ErrorT[];

interface ErrorsAction extends Action<string> {
  payload?: any;
  error?: any;
}

export const initialState = [];

function setErrors({
  handlerType = 'unhandled',
  errorMapFunction,
}: {
  handlerType?: string;
  errorMapFunction?: (rawError: ErrorT) => ErrorT;
}) {
  return (_state: ErrorsState, action: ErrorsAction): ErrorsState => {
    const { payload, error: showError } = action;

    if (!showError) return [];

    const rawErrors = Array.isArray(payload) ? payload : [payload];

    const errors = rawErrors.map(
      errorMapFunction
        ? errorMapFunction
        : (rawError) => ({
            ...rawError,
            handlerType,
          })
    );

    return errors;
  };
}

const FORM_HANDLER_ACTIONS = new Set<string>();
const UUID_FORM_HANDLER_ACTIONS = new Set<string>();
const IGNORE_HANDLER_ACTIONS = new Set<string>();

export function registerFormErrorAction<T extends string>(action: T): T {
  FORM_HANDLER_ACTIONS.add(action);

  return action;
}

export function registerUuidFormErrorAction<T extends string>(action: T): T {
  UUID_FORM_HANDLER_ACTIONS.add(action);

  return action;
}

export function registerIgnoreErrorAction<T extends string>(action: T): T {
  IGNORE_HANDLER_ACTIONS.add(action);

  return action;
}

export function errorsReducer(state: ErrorsState = initialState, action: ErrorsAction): ErrorsState {
  const { type } = action;

  switch (true) {
    case FORM_HANDLER_ACTIONS.has(type): {
      const reducer = setErrors({ handlerType: 'form' });

      return reducer(state, action);
    }

    case UUID_FORM_HANDLER_ACTIONS.has(type): {
      const reducer = setErrors({
        errorMapFunction: (rawError) => {
          if (!rawError) {
            return { handlerType: 'form' };
          }

          return {
            ...rawError,
            source:
              rawError.source && containsUuid(rawError.source) ? `identifier_${rawError.source}` : rawError.source,
            handlerType: 'form',
          };
        },
      });

      return reducer(state, action);
    }

    case IGNORE_HANDLER_ACTIONS.has(type): {
      return state;
    }

    case type === FORBIDDEN: {
      const reducer = setErrors({ handlerType: 'forbidden' });

      return reducer(state, action);
    }

    case type === NOT_FOUND: {
      const reducer = setErrors({ handlerType: 'not_found' });

      return reducer(state, action);
    }

    case type === METHOD_NOT_ALLOWED: {
      const reducer = setErrors({ handlerType: 'method_not_allowed' });

      return reducer(state, action);
    }

    case type === PAYLOAD_TOO_LARGE: {
      const reducer = setErrors({ handlerType: 'payload_too_large' });

      return reducer(state, action);
    }

    case type === LOCATION_CHANGE:
    case type === RESET_ERROR: {
      return initialState;
    }

    case type.endsWith('SUCCESS'): {
      if (state.length === 0) return initialState;

      return state.filter(({ failedAction }) => failedAction && getType(failedAction) !== getType(type));
    }

    case type.endsWith('FAILURE') || type.endsWith('ERROR'): {
      const reducer = setErrors({});

      return reducer(state, action);
    }

    case type === CANCEL_ACTION_BAR_ACTIVITY:
    case type === START_ACTIVITY: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
