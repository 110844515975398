import cx from 'classnames';
import { CSSProperties, MouseEvent } from 'react';

interface Props {
  children: string;
  className?: string;

  // classes
  isSpinning?: boolean;
  isSpinningReverse?: boolean;
  isHoverable?: boolean;
  size?: Size | number;
  color?: AppColor;

  // forwarded to `i` tag
  title?: string;
  style?: CSSProperties;
  role?: string;
  onClick?: (event: MouseEvent<HTMLElement, globalThis.MouseEvent>) => void;
}

/**
 * @example
 *   <Icon>warning</Icon>
 *   <Icon>material:warning</Icon>
 *   <Icon>app:session_ongoing</Icon>
 */
export function Icon({
  children,
  className,
  isSpinning = false,
  isSpinningReverse = false,
  isHoverable = false,
  size = 'normal',
  color,
  style,
  ...rest
}: Props) {
  const [vendorClass, iconName] = parseIcon(children);

  const getSize = () => {
    if (typeof size === 'number') return `${size}px`;

    if (size === 'smaller') return `${13}px`;
    if (size === 'small') return `${18}px`;
    if (size === 'large') return `${48}px`;
    if (size === 'larger') return `${64}px`;

    return undefined;
  };

  return (
    <i
      className={cx('fonticon', vendorClass, className, {
        '-is-spinning': isSpinning,
        '-is-spinning-reverse': isSpinningReverse,
        '-is-hoverable': isHoverable,
        '-color-success': color === 'success',
        '-color-danger': color === 'danger',
        '-color-warning': color === 'warning',
        '-color-info': color === 'info',
      })}
      style={{ ...style, '--size': getSize() }}
      {...rest}
    >
      {iconName}
    </i>
  );
}

/**
 * @example
 *   parseIcon('warning')               -> ['-vendor-material', 'warning']
 *   parseIcon('material:warning')      -> ['-vendor-material', 'warning']
 *   parseIcon('app:session_ongoing')   -> ['-vendor-app', 'session_ongoing']
 */
export function parseIcon(raw: string) {
  if (!raw.includes(':')) raw = `material:${raw}`;

  const [vendor, iconName] = raw.split(':');
  const vendorClass = `-vendor-${vendor}`;

  return [vendorClass, iconName] as const;
}
