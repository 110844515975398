import { Action } from 'redux';

import { getType } from 'utils/get-type';

// Reducer
//==============================================================================

interface PaginationMeta {
  totalCount?: number;
  pageSize?: number;
  totalPages?: number;
}

interface Pagination {
  currentPage?: number;
  pageSize?: number;
  totalCount?: number;
  totalPages?: number;
  result?: any;
}

interface PaginateState {
  [networkType: string]: Pagination | undefined;
}

interface PaginateAction extends Action<string> {
  payload: {
    request?: {
      page?: number;
    };
    response?: {
      meta?: PaginationMeta;
      data: any;
      result: {
        meta?: PaginationMeta;
        data: any;
      };
    };
  };
}

export const initialState = {};

export function paginationReducer(state: PaginateState = initialState, action: PaginateAction): PaginateState {
  const { type } = action;

  switch (true) {
    case type.endsWith('SUCCESS'): {
      const {
        payload: { request, response },
      } = action;

      if (!request || !response) return state;
      if (request.page === undefined) return state;

      if (response.meta && response.meta.totalCount !== undefined)
        return {
          ...state,
          [getType(type)]: {
            currentPage: request.page,
            pageSize: response.meta.pageSize,
            totalCount: response.meta.totalCount,
            totalPages: response.meta.totalPages,
            result: response.data,
          },
        };

      if (response.result.meta && response.result.meta.totalCount !== undefined)
        return {
          ...state,
          [getType(type)]: {
            currentPage: request.page,
            pageSize: response.result.meta.pageSize,
            totalCount: response.result.meta.totalCount,
            totalPages: response.result.meta.totalPages,
            result: response.result.data,
          },
        };

      return state;
    }

    default: {
      return state;
    }
  }
}
