import { fromJS } from 'immutable';

export function updatePatientTransplantStatus(state, action) {
  const {
    payload: {
      request: { patientId },
      response: {
        result: { status, note },
      },
    },
  } = action;

  return state.mergeDeepIn(
    ['patients', patientId],
    fromJS({
      transplantEligibilityStatus: status,
      transplantEligibilityNote: note,
    })
  );
}
