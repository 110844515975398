import { fromJS } from 'immutable';

import {
  CREATE_PATIENT,
  ENROLL_EMERGENCY_PATIENT,
  GET_PATIENT,
  GET_PATIENT_ALLERGY_INFO,
  GET_PATIENTS,
  MERGE_PATIENTS,
  REPLACE_ACTIVE_PATIENT_TREATMENTS,
  UPDATE_PATIENT,
  UpdateCareTeamArgs,
} from 'store/modules/entities/actions/patients';

import { deindex } from 'utils/deindex';
import { sortInline } from 'utils/sort-inline';

const PATH = ['patients'];

const mergePatientsHandler = (state: any, action: any) => {
  const {
    response: {
      entities: { patients },
    },
  } = action.payload;

  return state.mergeDeepIn(PATH, fromJS(patients || {}));
};

export const mergePatientHandler = (state: any, action: any) => {
  const {
    response: {
      entities: { patients, treatments },
    },
  } = action.payload;

  return state.mergeDeepIn(PATH, fromJS(patients || {})).mergeDeepIn(['treatments'], fromJS(treatments || {}));
};

export const mergePatientCareTeamHandler = (state: any, action: { type: string; payload: UpdateCareTeamArgs }) => {
  const { patientId, attendingDoctor, centerIds } = action.payload;

  return state
    .setIn([...PATH, patientId, 'attendingDoctor'], fromJS(attendingDoctor || null))
    .setIn([...PATH, patientId, 'centerIds'], fromJS(centerIds || []));
};

export function patientsReducer(state: any, action: any) {
  switch (action.type) {
    case REPLACE_ACTIVE_PATIENT_TREATMENTS: {
      const { patientId, activePatientTreatments } = action.payload;

      return state.setIn([...PATH, patientId, 'activePatientTreatments'], fromJS(activePatientTreatments));
    }

    case GET_PATIENTS.SUCCESS: {
      return mergePatientsHandler(state, action);
    }

    case GET_PATIENT.SUCCESS:
    case UPDATE_PATIENT.SUCCESS:
    case CREATE_PATIENT.SUCCESS:
    case ENROLL_EMERGENCY_PATIENT.SUCCESS:
    case MERGE_PATIENTS.SUCCESS: {
      return mergePatientHandler(state, action);
    }

    case GET_PATIENT_ALLERGY_INFO.SUCCESS: {
      const { response, patientId } = action.payload;

      return state.mergeDeepIn([...PATH, patientId], fromJS(response || {}));
    }

    default: {
      return state;
    }
  }
}

export const patientSelector = (state: any, id?: string): PatientT | null => {
  const { patients } = state.entities.toJS();

  if (!id || !patients[id]) return null;

  const patient = patients[id];

  return {
    ...patient,
    identifiers: patient.identifiers?.sort(sortInline(['primary', 'title'], ['desc', 'asc'])) || [],
  };
};

export const patientsSelector = (state: any): PatientT[] | null | undefined => {
  const { patients } = state.entities.toJS();

  if (!patients) return undefined;

  return deindex(patients);
};

export const paginatedPatientsSelector = (state: any, networkType?: string): PatientT[] | undefined => {
  const pagination = networkType ? state.pagination[networkType] : state.pagination.GET_PATIENTS;

  if (!pagination) return;

  const patientIdsForPage = pagination.result;

  const { patients } = state.entities.toJS();

  if (!patients) return;

  return patientIdsForPage.map((patientId: string) => patients[patientId]).filter(Boolean);
};
