import { ReactNode, Suspense, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import { useAppLocale } from 'hooks/locale/use-app-locale';

import { Loading } from 'components/loading';

type Messages = Record<string, string>;

let previousLocale: string | undefined;
let previousLanguage: string | undefined;

export function LocaleProvider({ children }: { children: ReactNode }) {
  const language = useAppLanguage();
  const locale = useAppLocale();

  return (
    <Suspense fallback={<AppInPreviousLanguage>{children}</AppInPreviousLanguage>}>
      <AppInCurrentLanguage language={language} locale={locale}>
        {children}
      </AppInCurrentLanguage>
    </Suspense>
  );
}

function AppInCurrentLanguage({
  language,
  locale,
  children,
}: {
  language: string;
  locale: string;
  children: ReactNode;
}) {
  const messages = useMessages(language);

  previousLocale = locale;
  previousLanguage = language;

  useSetDocumentLang(locale);

  return (
    <LocaleLoader messages={messages} locale={locale}>
      {children}
    </LocaleLoader>
  );
}

function AppInPreviousLanguage({ children }: { children: ReactNode }) {
  const cacheItem = previousLanguage ? MESSAGES_CACHE[previousLanguage] : undefined;
  const messages = cacheItem?.messages;

  if (!messages || !previousLocale) return <Loading />;

  return (
    <LocaleLoader messages={messages} locale={previousLocale}>
      {children}
    </LocaleLoader>
  );
}

function LocaleLoader({ messages, locale, children }: { messages: Messages; locale: string; children: ReactNode }) {
  return (
    <IntlProvider
      defaultLocale="en-GB"
      locale={locale}
      messages={messages}
      onError={(error: any) => {
        if (import.meta.env.DEV && error.code !== 'MISSING_TRANSLATION') {
          console.error(error); // eslint-disable-line no-console
        }
      }}
    >
      {children}
    </IntlProvider>
  );
}

interface Cache {
  error?: any;
  messages?: Messages;
  promise?: Promise<void>;
}

const MESSAGES_CACHE: Record<string, Cache> = {};

function useMessages(language: string) {
  MESSAGES_CACHE[language] ||= {};
  const cacheItem = MESSAGES_CACHE[language];

  if (cacheItem.error) throw cacheItem.error;
  if (cacheItem.messages) return cacheItem.messages;
  if (cacheItem.promise) throw cacheItem.promise;

  cacheItem.promise = import(`../translations/${language}.json`)
    .then((messages) => (cacheItem.messages = messages))
    .catch((error) => (cacheItem.error = error));

  throw cacheItem.promise;
}

const SUPPORTED_LANGUAGES = Object.keys(import.meta.glob('../translations/*.json', { eager: true, as: 'url' })).map(
  (filename) => filename.match(/([a-z0-9_]+)\.json$/)![1]
);

function useAppLanguage(): string {
  const appLocale = useAppLocale();
  const localeAsLanguage = appLocale.replace('-', '_');
  const languageFromLocale = appLocale.split('-')[0];

  if (SUPPORTED_LANGUAGES.includes(localeAsLanguage)) return localeAsLanguage;
  if (SUPPORTED_LANGUAGES.includes(languageFromLocale)) return languageFromLocale;

  return 'en';
}

function useSetDocumentLang(lang: string) {
  useEffect(() => {
    document.documentElement.lang = lang;

    return () => {
      document.documentElement.lang = '';
    };
  }, [lang]);
}
