import { fromJS, Set } from 'immutable';

import {
  CREATE_ADMINISTRATION_ROUTE,
  DELETE_ADMINISTRATION_ROUTE,
  GET_ADMINISTRATION_ROUTES,
  UPDATE_ADMINISTRATION_ROUTE,
} from 'store/modules/entities/actions/medication-management/administration-routes';
import {
  CREATE_MEDICINAL_INGREDIENT,
  DELETE_MEDICINAL_INGREDIENT,
  GET_MEDICINAL_INGREDIENTS,
  UPDATE_MEDICINAL_INGREDIENT,
} from 'store/modules/entities/actions/medication-management/ingredients';
import {
  CREATE_ADMINISTRATION_ROUTE_PRODUCT,
  CREATE_MEDICINAL_PRODUCT,
  CREATE_PRESCRIBABLE_UNIT,
  DELETE_ADMINISTRATION_ROUTE_PRODUCT,
  DELETE_MEDICINAL_PRODUCT,
  GET_MEDICINAL_PRODUCT,
  GET_MEDICINAL_PRODUCTS,
  GET_PRODUCT_ROUTES,
  SEARCH_INTRADIALYTIC_PRODUCTS,
  SYNC_MEDICINAL_PRODUCT,
  UPDATE_ADMINISTRATION_ROUTE_PRODUCT,
  UPDATE_MEDICINAL_PRODUCT,
  UPDATE_PRESCRIBABLE_UNIT,
} from 'store/modules/entities/actions/medication-management/products';
import {
  CREATE_MEDICINAL_PRODUCT_PROVIDER,
  DELETE_MEDICINAL_PRODUCT_PROVIDER,
  GET_MEDICINAL_PRODUCT_PROVIDERS,
  UPDATE_MEDICINAL_PRODUCT_PROVIDER,
} from 'store/modules/entities/actions/medication-management/providers';
import { SEARCH_IMPORTED_PRODUCT, SEARCH_PRODUCTS } from 'store/modules/search';
import { combineReducers } from 'store/utils';

import { deindex } from 'utils/deindex';
import { getSearchResults } from 'utils/get-search-results';
import { sortInline } from 'utils/sort-inline';

import { productConsumptionSetsReducer } from './consumption-sets';

const PROVIDERS_PATH = ['medicinalProductProviders'];
const INGREDIENTS_PATH = ['medicinalIngredients'];

const PRODUCTS_BY_ID_PATH = ['medicinalProductItems', 'byId'];
const PRODUCTS_BY_RESULT_PATH = ['medicinalProductItems', 'byResult'];

const INTRADIALYTIC_PRESCRIBABLE_PRODUCTS_BY_ID_PATH = ['intradialyticPrescribableMedicinalProductItems', 'byId'];

const PRESCRIBABLE_UNITS_BY_ID_PATH = ['prescribableUnits', 'byId'];
const PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH = ['prescribableUnits', 'byProductId'];
const ROUTES_BY_ID_PATH = ['medicinalAdministrationRoutes', 'byId'];
const ROUTES_BY_PRODUCT_ID_PATH = ['medicinalAdministrationRoutes', 'byProductId'];

const mergeProvider = (state: any, action: any): any => {
  const { response } = action.payload;
  const { medicinalProductProviders } = response.entities;

  return state.mergeIn(PROVIDERS_PATH, fromJS(medicinalProductProviders));
};

const mergeAdministrationRoute = (state: any, action: any): any => {
  const { response } = action.payload;
  const { administrationRoutes, medicinalProductProviders } = response.entities;

  return state
    .mergeIn(ROUTES_BY_ID_PATH, fromJS(administrationRoutes))
    .mergeIn(PROVIDERS_PATH, fromJS(medicinalProductProviders || {}));
};

const mergeIngredient = (state: any, action: any): any => {
  const { response } = action.payload;
  const { ingredients, medicinalProductProviders } = response.entities;

  return state.mergeIn(INGREDIENTS_PATH, fromJS(ingredients)).mergeIn(PROVIDERS_PATH, medicinalProductProviders);
};

const mergeProduct = (state: any, action: any): any => {
  const {
    response: {
      entities: {
        medicinalProductItems,
        packages,
        ingredients,
        administrationRoutes,
        prices,
        medicinalProductProviders,
      },
    },
  } = action.payload;

  return state
    .mergeDeepIn(PRODUCTS_BY_ID_PATH, fromJS(medicinalProductItems || {}))
    .mergeIn(['medicinalPackages'], fromJS(packages))
    .mergeIn(['medicinalProductIngredients'], fromJS(ingredients))
    .mergeIn(ROUTES_BY_ID_PATH, fromJS(administrationRoutes))
    .mergeIn(['medicinalPrices'], fromJS(prices))
    .mergeIn(PROVIDERS_PATH, fromJS(medicinalProductProviders));
};

export const medicationManagementReducer = combineReducers(indexReducer, productConsumptionSetsReducer);

function indexReducer(state: any, action: any) {
  switch (action.type) {
    case GET_MEDICINAL_PRODUCT_PROVIDERS.SUCCESS: {
      const { response } = action.payload;
      const { medicinalProductProviders } = response.entities;

      return state.setIn(PROVIDERS_PATH, fromJS(medicinalProductProviders || {}));
    }

    case CREATE_MEDICINAL_PRODUCT_PROVIDER.SUCCESS: {
      return mergeProvider(state, action);
    }

    case UPDATE_MEDICINAL_PRODUCT_PROVIDER.SUCCESS: {
      return mergeProvider(state, action);
    }

    case DELETE_MEDICINAL_PRODUCT_PROVIDER.SUCCESS: {
      const { id } = action.payload;

      return state.deleteIn([...PROVIDERS_PATH, id]);
    }

    case GET_ADMINISTRATION_ROUTES.SUCCESS: {
      const { response } = action.payload;
      const { administrationRoutes, medicinalProductProviders } = response.entities;

      return state
        .setIn(ROUTES_BY_ID_PATH, fromJS(administrationRoutes || {}))
        .mergeIn(PROVIDERS_PATH, fromJS(medicinalProductProviders || {}));
    }

    case CREATE_ADMINISTRATION_ROUTE.SUCCESS: {
      return mergeAdministrationRoute(state, action);
    }

    case UPDATE_ADMINISTRATION_ROUTE.SUCCESS: {
      return mergeAdministrationRoute(state, action);
    }

    case DELETE_ADMINISTRATION_ROUTE.SUCCESS: {
      const { id } = action.payload;

      return state.deleteIn([...ROUTES_BY_ID_PATH, id]);
    }

    case GET_MEDICINAL_INGREDIENTS.SUCCESS: {
      const { response } = action.payload;
      const { ingredients, medicinalProductProviders } = response.entities;

      return state
        .setIn(INGREDIENTS_PATH, fromJS(ingredients || {}))
        .mergeIn(PROVIDERS_PATH, medicinalProductProviders);
    }

    case CREATE_MEDICINAL_INGREDIENT.SUCCESS: {
      return mergeIngredient(state, action);
    }

    case UPDATE_MEDICINAL_INGREDIENT.SUCCESS: {
      return mergeIngredient(state, action);
    }

    case DELETE_MEDICINAL_INGREDIENT.SUCCESS: {
      const { id } = action.payload;

      return state.deleteIn([...INGREDIENTS_PATH, id]);
    }

    case GET_MEDICINAL_PRODUCTS.SUCCESS: {
      const {
        response: {
          entities: { medicinalProductItems },
          result: { data },
        },
      } = action.payload;

      return state
        .mergeDeepIn(PRODUCTS_BY_ID_PATH, fromJS(medicinalProductItems))
        .setIn(PRODUCTS_BY_RESULT_PATH, fromJS(data || []));
    }

    case SEARCH_PRODUCTS.SUCCESS: {
      const {
        response: {
          entities: { medicinalProductItems },
        },
      } = action.payload;

      return state.mergeDeepIn(PRODUCTS_BY_ID_PATH, fromJS(medicinalProductItems));
    }

    case SEARCH_IMPORTED_PRODUCT: {
      const {
        response: {
          entities: { medicinalProductItems, packages, prices },
        },
        path,
      } = action.payload;

      return state
        .mergeDeepIn([path, 'byId'], fromJS(medicinalProductItems || {}))
        .mergeIn(['medicinalPackages'], fromJS(packages || {}))
        .mergeIn(['medicinalPrices'], fromJS(prices || {}));
    }

    case SEARCH_INTRADIALYTIC_PRODUCTS.SUCCESS: {
      const { response } = action.payload;
      const { medicinalProductItems = {} } = response.entities;

      return state.setIn(INTRADIALYTIC_PRESCRIBABLE_PRODUCTS_BY_ID_PATH, fromJS(medicinalProductItems));
    }

    case DELETE_MEDICINAL_PRODUCT.SUCCESS: {
      const { id } = action.payload;

      const byResult = state.getIn(PRODUCTS_BY_RESULT_PATH).filter((productId) => productId !== id);

      return state.deleteIn([...PRODUCTS_BY_ID_PATH, id]).setIn(PRODUCTS_BY_RESULT_PATH, byResult);
    }

    case GET_MEDICINAL_PRODUCT.SUCCESS: {
      const {
        response: {
          entities: { prescribableUnits },
        },
        productId,
      } = action.payload;

      return mergeProduct(state, action)
        .mergeIn(PRESCRIBABLE_UNITS_BY_ID_PATH, fromJS(prescribableUnits))
        .setIn(
          [...PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH, productId],
          prescribableUnits ? Set(Object.keys(prescribableUnits)) : Set()
        );
    }

    case UPDATE_MEDICINAL_PRODUCT.SUCCESS: {
      return mergeProduct(state, action);
    }

    case UPDATE_ADMINISTRATION_ROUTE_PRODUCT.SUCCESS:
    case CREATE_ADMINISTRATION_ROUTE_PRODUCT.SUCCESS: {
      const {
        response: {
          entities: { administrationRoutes },
          result: { administrationRoutes: administrationRouteIds },
        },
        productId,
      } = action.payload;

      return state
        .setIn([...PRODUCTS_BY_ID_PATH, productId, 'administrationRoutes'], Set(administrationRouteIds))
        .mergeIn(ROUTES_BY_ID_PATH, fromJS(administrationRoutes));
    }

    case DELETE_ADMINISTRATION_ROUTE_PRODUCT.SUCCESS: {
      const { productId, routeId } = action.payload;

      const byProductId =
        state.getIn([...PRODUCTS_BY_ID_PATH, productId, 'administrationRoutes'])?.filter((id) => id !== routeId) || [];

      return state.setIn([...PRODUCTS_BY_ID_PATH, productId, 'administrationRoutes'], byProductId);
    }

    case CREATE_MEDICINAL_PRODUCT.SUCCESS: {
      const updatedState = mergeProduct(state, action);

      const {
        result: { product },
      } = action.payload.response;

      const productIds = updatedState.getIn(PRODUCTS_BY_RESULT_PATH).toJS();

      return updatedState.setIn(PRODUCTS_BY_RESULT_PATH, fromJS([product, ...productIds]));
    }

    case SYNC_MEDICINAL_PRODUCT.SUCCESS: {
      return state;
    }

    case GET_PRODUCT_ROUTES.SUCCESS: {
      const {
        response: {
          entities: { administrationRoutes },
          result: { administrationRoutes: administrationRouteIds },
        },
        productId,
      } = action.payload;

      return state
        .mergeIn(ROUTES_BY_ID_PATH, fromJS(administrationRoutes || {}))
        .setIn([...ROUTES_BY_PRODUCT_ID_PATH, productId], Set(administrationRouteIds));
    }

    case CREATE_PRESCRIBABLE_UNIT.SUCCESS: {
      const {
        response: {
          entities: { prescribableUnits },
          result: { prescribableUnits: ids },
        },
        productId,
      } = action.payload;

      const byProductId = state.getIn([...PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH, productId], Set()).union(Set(ids));

      return state
        .mergeIn(PRESCRIBABLE_UNITS_BY_ID_PATH, fromJS(prescribableUnits || {}))
        .mergeIn([...PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH, productId], byProductId);
    }

    case UPDATE_PRESCRIBABLE_UNIT.SUCCESS: {
      const {
        response: {
          entities: { prescribableUnits },
          result: { prescribableUnits: ids },
        },
        productId,
      } = action.payload;

      const byProductId = state.getIn([...PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH, productId], Set()).union(Set(ids));

      return state
        .mergeIn(PRESCRIBABLE_UNITS_BY_ID_PATH, fromJS(prescribableUnits || {}))
        .mergeIn([...PRESCRIBABLE_UNITS_BY_PRODUCT_ID_PATH, productId], byProductId);
    }

    default: {
      return state;
    }
  }
}

export const medicinalProductProvidersSelector = (
  state: any,
  enabledOnly = false
): MedicinalProductProviderT[] | null | undefined => {
  const { medicinalProductProviders } = state.entities.toJS();

  if (state.network.GET_MEDICINAL_PRODUCT_PROVIDERS) return undefined;

  return deindex<MedicinalProductProviderT>(medicinalProductProviders).filter(({ enabled }) =>
    enabledOnly ? enabled : true
  );
};

export const productProviderOptionsSelector = (
  state: any
): (OptionT & {
  supervised?: boolean;
})[] => {
  const sources = medicinalProductProvidersSelector(state);

  if (!sources) return [];

  return sources.map(({ id, name, supervised }) => ({
    value: id,
    label: name,

    supervised,
  }));
};

export const administrationRoutesSelector = (state: any): MedicinalAdministrationRouteT[] | null | undefined => {
  const {
    medicinalAdministrationRoutes: { byId },
    medicinalProductProviders,
  } = state.entities.toJS();

  if (state.network.GET_ADMINISTRATION_ROUTES) return undefined;

  return deindex<MedicinalAdministrationRouteT>(byId)
    .sort(sortInline('name'))
    .map((r: any) => ({
      ...r,
      source: medicinalProductProviders?.[r.source],
    }));
};

export const ingredientsSelector = (state: any): MedicinalIngredientT[] | null | undefined => {
  const { medicinalIngredients, medicinalProductProviders } = state.entities.toJS();

  if (state.network.GET_INGREDIENTS) return undefined;

  return deindex<MedicinalIngredientT>(medicinalIngredients)
    .sort(sortInline('name'))
    .map((i: any) => ({
      ...i,
      source: medicinalProductProviders?.[i.source],
    }));
};

export const medicinalProductItemsSelector = (state: any): MedicinalProductItemT[] | null | undefined => {
  const isFetching = !!state.network.GET_MEDICINAL_PRODUCTS;
  const {
    medicinalProductItems: { byId, byResult },
  } = state.entities.toJS();

  if (isFetching || !byId || !byResult) return undefined;

  return byResult.map((id: string) => byId[id]);
};

export const medicinalProductItemSelector = (state: any, id: string): MedicinalProductItemT | undefined => {
  const {
    medicinalProductItems: { byId },
  } = state.entities.toJS();

  if (!byId[id]) return undefined;

  return byId[id];
};

export const productsSearchResultsSelector = (state: any) => {
  return getSearchResults(state, ['medicinalProductItems', 'byId'], 'products').sort(sortInline('name'));
};

export const productRoutesOptionsSelector = (state: any, productId?: string): OptionT[] => {
  if (state.network.GET_PRODUCT_ROUTES) return [];
  if (!productId) return [];

  const { byId, byProductId } = state.entities.getIn(['medicinalAdministrationRoutes']).toJS();

  if (!byId || !byProductId[productId]) return [];

  return byProductId[productId]
    .map((id) => byId[id])
    .filter(Boolean)
    .map((route) => ({ label: route.name, value: route.id }));
};

export const defaultProductRouteSelector = (
  state: any,
  productId?: string
): MedicinalAdministrationRouteT | undefined => {
  if (state.network.GET_PRODUCT_ROUTES) return;
  if (!productId) return;

  const { byId, byProductId } = state.entities.getIn(['medicinalAdministrationRoutes']).toJS();

  if (!byId || !byProductId[productId]) return;

  return byProductId[productId]
    .map((id) => byId[id])
    .filter(Boolean)
    .find((route) => !!route.defaultForProduct);
};

const forProductSelector =
  <T>(storeEntry: string | string[], objectEntry: string) =>
  (state: any, productId: string): T[] | undefined => {
    const entities = state.entities.toJS();
    const {
      medicinalProductItems: { byId },
    } = entities;

    const entityEntry = Array.isArray(storeEntry)
      ? storeEntry.reduce((value, entry) => value?.[entry], entities)
      : entities[storeEntry];

    if (!byId[productId] || !entityEntry) return undefined;
    if (!byId[productId][objectEntry]) return [];

    const ids = byId[productId][objectEntry];

    return ids.map((id) => entityEntry[id]).filter((entity) => !!entity);
  };

export const packagesForProductSelector = (state: any, productId: string): MedicinalPackageT[] | null | undefined => {
  const packages = forProductSelector<MedicinalPackageT>('medicinalPackages', 'packages')(state, productId);

  if (!packages) return undefined;

  const { medicinalPrices } = state.entities.toJS();

  return packages.map((p) => ({
    ...p,
    prices: medicinalPrices && p.prices ? p.prices.map((priceId: any) => medicinalPrices[priceId]) : [],
  }));
};

export const activeIngredientsForProductSelector = forProductSelector<MedicinalProductIngredientT>(
  'medicinalProductIngredients',
  'activeIngredients'
);

export const excipientIngredientsForProductSelector = forProductSelector<MedicinalProductIngredientT>(
  'medicinalProductIngredients',
  'excipientIngredients'
);

export const administrationRoutesForProductSelector = forProductSelector<MedicinalAdministrationRouteT>(
  ROUTES_BY_ID_PATH,
  'administrationRoutes'
);

export const prescribableUnitsForProductSelector = (state: any, productId: string): PrescribableUnitT[] => {
  if (!productId) return [];

  const { byId, byProductId } = state.entities.getIn(['prescribableUnits']).toJS();

  if (!byId || !byProductId[productId]) return [];

  const ids = byProductId[productId];

  return ids.map((id) => byId[id]);
};

export const prescribableIntradialyticProductsSelector = (
  state: any
): IntradialyticPrescribableMedicinalProductItemT[] => {
  const products = state.entities.getIn(INTRADIALYTIC_PRESCRIBABLE_PRODUCTS_BY_ID_PATH).toJS();

  if (!products) return [];

  return Object.keys(products)
    .map((key) => ({ ...products[key], comboId: key }))
    .filter(Boolean)
    .sort(sortInline('name'));
};
