import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { locationChange } from 'store/modules/router';

import { useAppDispatch } from 'hooks/use-redux';

export function RouteWatcher() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(locationChange(pathname));
  }, [dispatch, pathname]);

  return null;
}
