import { Action } from 'redux';

import { CANCEL_ACTION_BAR_ACTIVITY, START_ACTIVITY } from 'store/modules/activity';
import { LOCATION_CHANGE } from 'store/modules/router';

import { getType } from 'utils/get-type';

// Actions
//==============================================================================

export const RESET_SUCCESS = 'RESET_SUCCES';

// Action Creators
//==============================================================================

export function resetSuccess() {
  return { type: RESET_SUCCESS };
}

// Reducer
//==============================================================================

export interface SuccessState {
  type?: string;
  [key: string]: any;
}

interface SuccessAction extends Action<string> {
  payload: any;
}

export const initialState = {};

function setResourceSuccess(resource: string, newId: string) {
  return (state: SuccessState, action: SuccessAction) => {
    const {
      type,
      payload: { request, response },
    } = action;

    if (!request && !response) return state;

    let id;

    if (!id && request) id = request.id;
    if (!id && response) id = response[newId];

    return { type: getType(type), [resource]: { id } };
  };
}

function setGenericSuccess(state: SuccessState, { type }: SuccessAction) {
  return { type: getType(type) };
}

export function successReducer(state: SuccessState = initialState, action: SuccessAction): SuccessState {
  const { type } = action;

  switch (true) {
    case type === 'CREATE_PATIENT_SUCCESS' || type === 'MERGE_PATIENTS_SUCCESS': {
      const reducer = setResourceSuccess('patient', 'result');

      return reducer(state, action);
    }

    case type === 'ENROLL_EMERGENCY_PATIENT_SUCCESS': {
      const { type, payload } = action;

      return { type: getType(type), payload };
    }

    case type.includes('PATIENT') && type.endsWith('SUCCESS'): {
      return setGenericSuccess(state, action);
    }

    case type === 'CREATE_MEDICINAL_PRODUCT_SUCCESS': {
      const {
        type,
        payload: { response },
      } = action;

      const id = response?.result?.product;

      return { type: getType(type), product: { id } };
    }

    case type === LOCATION_CHANGE:
    case type === RESET_SUCCESS:
    case type === CANCEL_ACTION_BAR_ACTIVITY:
    case type === START_ACTIVITY:
    case type.endsWith('FAILURE'): {
      return initialState;
    }

    // Save entire payload for success without handler
    case type.endsWith('SUCCESS'): {
      const { type, payload } = action;

      return { type: getType(type), payload };
    }

    default: {
      return state;
    }
  }
}
