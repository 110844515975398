import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, Suspense } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { store } from 'store';

import { namedLazy } from 'utils/named-lazy';

import {
  AuthProvider,
  CapabilitiesProvider,
  CurrentUserProvider,
  DefaultQueryClientProvider,
  ErrorBoundary,
  LocaleProvider,
  RouteWatcher,
  ToastProvider,
  useAuth,
} from 'providers';

import { Loading } from 'components/loading';

const AuthenticatedApp = namedLazy(() => import('./components/authenticated'), 'AuthenticatedApp');
const UnauthenticatedApp = namedLazy(() => import('./components/unauthenticated'), 'UnauthenticatedApp');

export default function Root() {
  return (
    <StoreProvider store={store}>
      <DefaultQueryClientProvider>
        <AuthProvider>
          <AuthSwitch />
        </AuthProvider>
        <ToastProvider />

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </DefaultQueryClientProvider>
    </StoreProvider>
  );
}

function AuthSwitch() {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? (
    <CurrentUserProvider>
      <CapabilitiesProvider mode="authenticated">
        <App>
          <AuthenticatedApp />
        </App>
      </CapabilitiesProvider>
    </CurrentUserProvider>
  ) : (
    <CapabilitiesProvider mode="unauthenticated">
      <App>
        <UnauthenticatedApp />
      </App>
    </CapabilitiesProvider>
  );
}

function App({ children }: { children: ReactNode }) {
  return (
    <LocaleProvider>
      <ErrorBoundary>
        <Router>
          <Suspense fallback={<Loading />}>{children}</Suspense>
          <RouteWatcher />
        </Router>
      </ErrorBoundary>
    </LocaleProvider>
  );
}
