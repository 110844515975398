import { combineReducers } from 'redux';

import { activityReducer } from 'store/modules/activity';
import { entitiesReducer } from 'store/modules/entities/reducers';
import { errorsReducer } from 'store/modules/errors';
import { formsReducer } from 'store/modules/form';
import { networkReducer } from 'store/modules/network';
import { paginationReducer } from 'store/modules/pagination';
import { searchReducer } from 'store/modules/search';
import { successReducer } from 'store/modules/success';

export const reducer = combineReducers({
  activity: activityReducer,
  entities: entitiesReducer,
  errors: errorsReducer,
  form: formsReducer,
  network: networkReducer,
  pagination: paginationReducer,
  search: searchReducer,
  success: successReducer,
});
